<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Add page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <div class="flex">
                            <p class="mr-10"> Aktive</p> 
                            <Check v-model="Pages.status" /> 
                        </div>
                    </div> 
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="title_ua">
                            <el-input v-model="Pages.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.description_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item">
                        <p>Link</p>
                        <el-form-item prop="url">
                            <el-input v-model="Pages.url"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Link Name</p>
                        <el-form-item prop="url_name_ua">
                            <el-input v-model="Pages.url_name_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Link Description</p>
                        <el-form-item prop="url_description_ua">
                            <el-input v-model="Pages.url_description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="icon">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Icon Upload
                                    <input type="file" name="image" @change="UploadIcon" accept="image/png, image/gif, image/jpeg">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.icon.name? Pages.icon.name:'Upload icon'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload Icon</p>
                        </el-form-item>
                    </div>

                    <div class="item flex items-center">
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.nav" class="mt-20"> Add nav</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox size="medium" v-model="Pages.footer" class="mt-20"> Add footer</el-checkbox>
                        </el-form-item>
                    </div>
                    <h2 class="admin-h2-title">Templates</h2>
                     <div class="item flex items-center fw-w">
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.credit_component" class="mt-20"> Розрахунок позики</el-checkbox>
                        </el-form-item>
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.stocks_component" class="mt-20"> Акції</el-checkbox>
                        </el-form-item>
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.leasing_component" class="mt-20"> Лізинг</el-checkbox>
                        </el-form-item>
                         <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.icon_component" class="mt-20"> Переваги</el-checkbox>
                        </el-form-item>
                         <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.contact_component" class="mt-20"> Контакти </el-checkbox>
                        </el-form-item>
                         <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.links_component" class="mt-20"> Top linkes</el-checkbox>
                        </el-form-item>
                    </div>

                     <h2 class="admin-h2-title">SEO</h2>
                      <div class="item">
                        <p> Title</p>
                        <el-form-item prop="seo_title">
                            <el-input v-model="Pages.seo_title"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="seo_description"> 
                            <el-input type="textarea" :rows="4" v-model="Pages.seo_description"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Keywords</p>
                        <el-form-item prop="seo_keywords">
                            <el-input v-model="Pages.seo_keywords"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="seo_image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Upload Image
                                    <input type="file" name="image" @change="SEOimage" accept="image/png, image/gif, image/jpeg">
                                </button>
                                <p :class="erros.seo_image?'c-red':''"> {{Pages.seo_image.name? Pages.seo_image.name:'Upload icon'}} </p>
                            </div>
                            <p v-if="erros.seo_image" class="error-text">Upload Image</p>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                    <div class="item">
                        <p> Title</p>
                        <el-form-item prop="title_en">
                            <el-input v-model="Pages.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.description_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item">
                        <p>Link Name</p>
                        <el-form-item prop="url_name_en">
                            <el-input v-model="Pages.url_name_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Link Description</p>
                        <el-form-item prop="url_description_en">
                            <el-input v-model="Pages.url_description_en"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/pages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Создать</button>
                </div>
            </el-form>
        </div>

    </div>
</div>
</template>

<script>
import Check from '../../../components/checknum.vue' 
export default {
    components:{
        Check
    },
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                status:0,
                index: 1,
                title_ua: '',
                title_en: '',
                description_ua: '',
                description_en: '',
                url: '',
                url_name_ua: '',
                url_name_en: '',
                url_description_ua: '',
                url_description_en: '',
                icon: '',

                nav: false, 
                footer: false,

                credit_component: false,
                contact_component: false,
                leasing_component: false,
                links_component: false,
                stocks_component: false,
                icon_component: false,

                seo_title: '',
                seo_description: '',
                seo_keywords: '',
                seo_image: '',
            },
            PagesRules: {
                index: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                title_ua:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                title_en:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                description_ua:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                description_en:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                url:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                url_name_ua:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                url_name_en:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                url_description_ua:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                url_description_en:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                icon_component:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                icon:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                 seo_title:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                seo_description:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                seo_keywords:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                seo_image:  [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }], 
                
                

            },
        }

    },
    watch: {
        'Stock.image'() {
            if (this.Stock.image) {
                this.erros.image = false
            }
        }
    },
    methods: {
        UploadIcon(event) {
            this.Pages.icon = event.target.files[0];

        },
        SEOimage(event) {
            this.Pages.seo_image = event.target.files[0];
        },

        PostStock() {
            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true

                    let data = new FormData();
                    for (let i of Object.keys(this.Pages)) {
                        if(this.Pages[i]==true || this.Pages[i]==false){
                            if(this.Pages[i]==true){this.Pages[i]=1}
                            if(this.Pages[i]==false){this.Pages[i]=0}
                        }
                        data.append(i, this.Pages[i]);
                    }

                    this.axios.post('/admin/page', data).then(res => {
                            console.log(res)
                            this.$router.push('/admin/pages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                 title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    if(this.Pages.index && this.Pages.title_ua && this.Pages.description_ua && this.Pages.url && this.Pages.url_name_ua && this.Pages.url_description_ua && this.Pages.icon && this.Pages.seo_title && this.Pages.seo_description && this.Pages.seo_keywords && this.Pages.seo_image){
                        this.langName='en'
                    }
                    if(this.Pages.title_en && this.Pages.description_en && this.Pages.url_name_en && this.Pages.url_description_en){
                        this.langName='ua'
                    }
                }

            })

        },
         GetPage() {
            this.loading = true
            this.axios.get(`/admin/page/${this.$route.params.id}`)
                .then(res => {
                    for (let i of Object.keys(this.Pages)) {
                        console.log(i)
                        this.Pages[i] = res.data.data[i]? res.data.data[i]:''
                    } 
                    this.Pages._method = 'PUT'
                })
                .catch(error => {
                    let ERRORS = error.response.data.message;
                    let keys = Object.keys(ERRORS);
                    let error_msg = ERRORS[keys[0]];
                    this.$notify.error({
                        title: 'Произошла ошибка',
                        message: error_msg ? error_msg : "Произошла ошибка",
                    });

                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}
.admin-h2-title{
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
